import { defineStore } from 'pinia'

export const useHeaderStore = defineStore('header', {
  state: () => ({
    backBtnLink: '/',
    logoVisible: true,
    titleVisible: false,
    title: 'INTRO',
    isShowGnb: false,
  }),
  actions: {
    setHeader(payload) {
      this.backBtnLink = payload.backBtnLink
      this.logoVisible = payload.logoVisible
      this.titleVisible = payload.titleVisible
      this.title = payload.title
    },
    showGnb(showState) {
      this.isShowGnb = showState
    },
  },
})
